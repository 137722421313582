export default {
    DRAFT: {
        label: 'Brouillon',
        colorClass: 'light',
    },
    PENDING: {
        label: 'En cours',
        colorClass: 'primary',
    },
    ACCEPTED: {
        label: 'Accepté',
        colorClass: 'primary',
    },
    DONE: {
        label: 'Terminé',
        colorClass: 'success',
        relatedDateField: 'doneAt',
    },
    CANCELED: {
        label: 'Annulé',
        colorClass: 'danger',
        relatedDateField: 'canceledAt',
    },
};