export default {
    PENDING_DIAGNOSIS: {
        label: 'En attente de diagnostic',
        colorClass: 'warning',
        canEditQuotation: true,
    },
    PENDING_QUOTATION_ACCEPTANCE: {
        label: 'En attente d\'acceptation devis',
        colorClass: 'primary',
        canEditQuotation: true,
    },
    PENDING_PARTS: {
        label: 'En attente de pièces',
        colorClass: 'info',
        canEditQuotation: true,
    },
    PENDING_REPAIRS: {
        label: 'En attente de réparation',
        colorClass: 'info',
        canEditQuotation: false,
    },
    QUOTATION_REFUSED: {
        label: 'Devis refusé',
        colorClass: 'warning',
        canEditQuotation: false,
    },
    DONE: {
        label: 'Terminé',
        colorClass: 'success',
        canEditQuotation: false,
        relatedDateField: 'doneAt',
    },
    CANCELED: {
        label: 'Annulé',
        colorClass: 'danger',
        canEditQuotation: false,
        relatedDateField: 'canceledAt',
    },
};