<template>
  <div class="chats">
    <div
      v-for="(msgData, index) in messages"
      :key="String(msgData.author.id)+String(index)"
      class="chat"
    >
      <div class="chat-body">
        <div class="chat-content">
          <p>
            {{ msgData.content }}
          </p>
          <small>
            {{ msgData.author.firstname }} {{ msgData.author.lastname }}, le {{ (new Date(msgData.createdAt)).toLocaleDateString() }} à {{ (new Date(msgData.createdAt)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { computed } from '@vue/composition-api'

export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
[dir=ltr] .chat-app-window .chat-app-form {
  padding: 0;
  margin-bottom: 12px;
}

.chats {

    .chat {
        margin: 12px 0;

        .chat-body {
            margin: 0 !important;
            width: 100%;
            background: #f2f2f2;
            padding: 10px;
            border-radius: 4px;

            .chat-content {
                p {
                    margin: 0 0 12px 0;
                }

                small {
                    font-size: 0.9em;
                    font-style: italic;
                }
            }
        }
    }
}
</style>
